import React from 'react'
import { graphql, Link } from "gatsby"
import Layout from '../components/layout'
import BlogPostSmall from '../components/blogpost-small'
import styled from 'styled-components'
import SEO from "../components/seo"
import ReactMarkdown from 'react-markdown'

const Intro = styled.div`
  margin-bottom: 100px;
  color: #279896;
  // font-weight: bold;
  font-size: 1.2rem;
  line-height: 30px;
`

const SeasonLabel = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: -10px;
  color: #999;
`

const SeasonText = styled.span`
  & a {
    color: #279896;
  }
`
const BlogPosts = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 40px;
`

const SeasonLink = styled(props => <Link {...props} />)`
  color: #333;
  text-decoration: none;
  &:hover {
    color: #279896;
  }
  &.activeSeason {
    font-weight: bold;
    color: #279896;
  }
`

const seasonLabels = {
  1: 'januari, februari, maart',
  2: 'april, mei, juni',
  3: 'juli, augustus, september',
  4: 'oktober, november, december',
}


const SeasonPage = ({ data, pageContext }) => {
  const { frontmatter, rawMarkdownBody } = data.season.childMarkdownRemark

// uit de context halen dat je op homepage zit


  return (
    <div>
      <Layout active={pageContext.homepage ? 'index' : 'agenda'}>
        <SEO title={pageContext.homepage ? "Home" : "Agenda"} />
        {pageContext.homepage ? (
            <Intro>
              BILDNIS is een onafhankelijk en actief platform voor kunstliefhebbers om kennis, ervaring, interesse en fascinatie rond hedendaagse beeldende kunst uit te wisselen. BILDNIS is gegroeid uit de Oostendse vereniging Vrienden Kunstmuseum aan Zee (provincie West-Vlaanderen) en organiseert een reeks evenementen per seizoen. Telkens focussen we ons op een thema dat een aaneenrijging van activiteiten met zich meebrengt.
            </Intro>
          ) : null
        }
        <h3>{`${frontmatter.year}#${frontmatter.season} ${frontmatter.title}`}</h3>
        <SeasonLabel>{seasonLabels[frontmatter.season]}</SeasonLabel>
        <SeasonText><ReactMarkdown source={rawMarkdownBody} parserOptions={{commonmark:true}} /></SeasonText>
        <BlogPosts>
        {
          data.blogposts.edges.map(({ node }, idx) =>
            <BlogPostSmall key={idx} node={node} />
          )
        }
        </BlogPosts>

        <h4>Vorige seizoenen</h4>
        {
          data.otherseasons.edges.map(({ node }, idx) => {
            const { year, season, title } = node.childMarkdownRemark.frontmatter
            return (
              <div key={idx}><SeasonLink className={year === pageContext.year && season === pageContext.season ? 'activeSeason':''} to={idx === 0 ? `/agenda` : `/season/${year}/${season}`}>{`${year}#${season} ${title}`}</SeasonLink></div>
            )
          })
        }
      </Layout>
    </div>
  )
}

export default SeasonPage

export const seasonPageQuery = graphql`
  query seasonPageQuery($year: Date!, $season: String!) {
    season: file(relativeDirectory: {eq: "seasons"}, childMarkdownRemark: {frontmatter: {year: {eq: $year}, season: {eq: $season}}}) {
      childMarkdownRemark {
        rawMarkdownBody
        frontmatter {
          title
          year
          season
        }
      }
    }
    blogposts: allFile(filter: {relativeDirectory: {eq: "blog"}, childMarkdownRemark: {frontmatter: {year: {eq: $year}, season: {eq: $season}}} }, sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC}) {
      edges {
        node {
          childMarkdownRemark {
            rawMarkdownBody
            frontmatter {
              date
              title
              coverImage {
                childImageSharp {
                  fluid(maxWidth: 750, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                  }
                }
                publicURL
              }
            }
          }
        }
      }
    }
    otherseasons: allFile(filter: {relativeDirectory: {eq: "seasons"}}, sort: {fields: [childMarkdownRemark___frontmatter___year, childMarkdownRemark___frontmatter___season], order: [DESC, DESC]}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              year
              season
            }
          }
        }
      }
    }
  }
`
