import React from 'react'
import { Link } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image"
import moment from 'moment'
import 'moment/locale/nl'
moment.locale('nl');

const BlogPost = styled.div`
    color: #666;
    width: 46%;
    @media (max-width: 768px) {
      width: 96%;
    }
    margin: 2%;
`

const CardText = styled.div`
  height: 120px;
  width: 100%;
  font-size: 16px;
  background: rgba(248,248,248);
  font-weight: bold;
`

const BlogPostLink = styled(props => <Link {...props} />)`
  color: #666;
  text-decoration: none;
`

const Title = styled.h5`
  padding: 5px 20px;
`

const Date = styled.div`
  padding: 20px 20px 0px;
  color: #279896;
`


const BlogPostSmall = ({ node }) => {
  const { childMarkdownRemark } = node
  const { frontmatter } = childMarkdownRemark
  return (

    <BlogPost>
      {frontmatter.coverImage != null ?
        <Link to={`/blog/${frontmatter.title.substr(0,100).replace(/[? *]/g, '-')}`}>
          {
            frontmatter.coverImage.childImageSharp != null ? (
              <Img
                fluid={frontmatter.coverImage.childImageSharp.fluid}
              />
            ) : (
              <img src={frontmatter.coverImage.publicURL} style={{ marginBottom: '-0.5rem' }} />
            )
          }
        </Link>
        : null
      }
      <CardText>
        <BlogPostLink to={`/blog/${frontmatter.title.substr(0,100).replace(/[? *]/g, '-')}`}>
          <Date>
            {moment(frontmatter.date, "YYYY-MM-DD").format('dd DD.MM')}
          </Date>
          <Title>{frontmatter.title}</Title>
        </BlogPostLink>
      </CardText>
    </BlogPost>
  
  )
}

export default BlogPostSmall
